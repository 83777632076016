var render = function render(){
  var _vm$role;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-card', {
    staticClass: "d-flex flex-column",
    attrs: {
      "outlined": "",
      "elevation": "0",
      "min-height": "80vh"
    }
  }, [_c('v-card-title', {
    staticClass: "text-h5 font-weight-medium"
  }, [_c('v-btn', {
    staticClass: "px-n3",
    attrs: {
      "color": "#122333"
    },
    on: {
      "click": _vm.back
    }
  }, [_c('v-icon', {
    attrs: {
      "color": "white"
    }
  }, [_vm._v(" " + _vm._s(_vm.mdiArrowLeft) + " ")])], 1), _c('div', {
    staticClass: "ml-5"
  }, [_vm._v("Create New Assessment")])], 1), _c('v-stepper', {
    attrs: {
      "elevation": "0"
    },
    model: {
      value: _vm.pos,
      callback: function ($$v) {
        _vm.pos = $$v;
      },
      expression: "pos"
    }
  }, [_c('v-stepper-header', [_c('v-stepper-step', {
    attrs: {
      "complete": _vm.pos > 1,
      "step": "1"
    }
  }, [_vm._v(" Basic Details ")]), _c('v-divider'), _c('v-stepper-step', {
    attrs: {
      "complete": _vm.pos > 2,
      "step": "2"
    }
  }, [_vm._v(" Select Skills ")]), _c('v-divider'), _c('v-stepper-step', {
    attrs: {
      "complete": _vm.pos > 3,
      "step": "3"
    }
  }, [_vm._v(" Settings ")]), _c('v-divider'), _c('v-stepper-step', {
    attrs: {
      "step": "4"
    }
  }, [_vm._v(" Final Preview ")])], 1), _c('v-stepper-items', [_c('v-stepper-content', {
    attrs: {
      "step": "1"
    }
  }, [_c('v-form', {
    ref: "basicDetails",
    staticClass: "basic pa-5",
    attrs: {
      "lazy-validation": ""
    },
    model: {
      value: _vm.basicStep,
      callback: function ($$v) {
        _vm.basicStep = $$v;
      },
      expression: "basicStep"
    }
  }, [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('label', {
    staticClass: "font-weight-bold text-body-1",
    attrs: {
      "for": "testname"
    }
  }, [_vm._v(" Assessment Name ")]), _c('v-text-field', {
    attrs: {
      "id": "testname",
      "outlined": "",
      "placeholder": "Name your assessment",
      "rules": [function (v) {
        return !!v || 'Required';
      }]
    },
    model: {
      value: _vm.testname,
      callback: function ($$v) {
        _vm.testname = $$v;
      },
      expression: "testname"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('label', {
    staticClass: "font-weight-bold text-body-1",
    attrs: {
      "for": "jobrole"
    }
  }, [_vm._v(" Role ")]), _c('v-text-field', {
    attrs: {
      "id": "jobrole",
      "outlined": "",
      "placeholder": "Enter Job Role"
    },
    model: {
      value: _vm.role,
      callback: function ($$v) {
        _vm.role = $$v;
      },
      expression: "role"
    }
  })], 1)], 1), _c('label', {
    staticClass: "font-weight-bold text-body-1",
    attrs: {
      "for": "desc"
    }
  }, [_vm._v(" Description ")]), _c('v-textarea', {
    attrs: {
      "id": "desc",
      "outlined": "",
      "rows": "8",
      "placeholder": "Enter description about assessment and/or job role"
    },
    model: {
      value: _vm.description,
      callback: function ($$v) {
        _vm.description = $$v;
      },
      expression: "description"
    }
  })], 1)], 1), _c('v-stepper-content', {
    attrs: {
      "step": "2"
    }
  }, [_c('skills', {
    attrs: {
      "skills": _vm.skills,
      "skillTags": _vm.skillTags,
      "hiringPlan": _vm.hiringPlan
    },
    on: {
      "addskill": function (skillA) {
        return _vm.addSkillToTest(skillA);
      },
      "removeskill": function (skillR) {
        return _vm.removeSkillFromTest(skillR);
      }
    }
  })], 1), _c('v-stepper-content', {
    attrs: {
      "step": "3"
    }
  }, [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('v-card', {
    staticClass: "pa-5",
    attrs: {
      "elevation": "0"
    }
  }, [_c('v-card-title', [_vm._v(" Anti-Cheat Settings ")]), _c('v-card-text', [_c('v-checkbox', {
    attrs: {
      "disabled": "",
      "value": 1,
      "label": "Disallow External Copy Paste"
    },
    model: {
      value: _vm.antiCheat,
      callback: function ($$v) {
        _vm.antiCheat = $$v;
      },
      expression: "antiCheat"
    }
  }), _c('v-checkbox', {
    attrs: {
      "disabled": "",
      "value": 2,
      "label": "Make Fullscreen"
    },
    model: {
      value: _vm.antiCheat,
      callback: function ($$v) {
        _vm.antiCheat = $$v;
      },
      expression: "antiCheat"
    }
  }), _c('v-checkbox', {
    attrs: {
      "disabled": "",
      "value": 3,
      "label": "Log Tab Change Events"
    },
    model: {
      value: _vm.antiCheat,
      callback: function ($$v) {
        _vm.antiCheat = $$v;
      },
      expression: "antiCheat"
    }
  }), _c('v-checkbox', {
    attrs: {
      "value": 5,
      "label": "Take Video Recording"
    },
    model: {
      value: _vm.antiCheat,
      callback: function ($$v) {
        _vm.antiCheat = $$v;
      },
      expression: "antiCheat"
    }
  })], 1)], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('v-card', {
    staticClass: "pa-5",
    attrs: {
      "elevation": "0"
    }
  }, [_c('v-card-title', [_vm._v(" Test Settings ")]), _c('v-card-text', [_c('v-checkbox', {
    attrs: {
      "label": "Enable Test Invite by URL"
    },
    model: {
      value: _vm.testByUrlEnabled,
      callback: function ($$v) {
        _vm.testByUrlEnabled = $$v;
      },
      expression: "testByUrlEnabled"
    }
  }), _vm.testByUrlEnabled ? _c('div', [_c('label', {
    staticClass: "text-h6 black--text",
    attrs: {
      "for": "url-expiry-picker"
    }
  }, [_vm._v(" Set Expiry Date for Test URL ")]), _c('div', [_c('v-date-picker', {
    attrs: {
      "id": "url-expiry-picker",
      "allowed-dates": _vm.allowFutureDates,
      "elevation": "1"
    },
    model: {
      value: _vm.testUrlExpiryEpoch,
      callback: function ($$v) {
        _vm.testUrlExpiryEpoch = $$v;
      },
      expression: "testUrlExpiryEpoch"
    }
  })], 1)]) : _vm._e(), _c('div', [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-checkbox', {
    attrs: {
      "label": "Set minimum score for assessment"
    },
    model: {
      value: _vm.setMinScore,
      callback: function ($$v) {
        _vm.setMinScore = $$v;
      },
      expression: "setMinScore"
    }
  })], 1)], 1), _vm.setMinScore ? _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "5"
    }
  }, [_c('v-text-field', {
    attrs: {
      "label": "Minimum score",
      "type": "number",
      "rules": [_vm.minScoreRule]
    },
    model: {
      value: _vm.minScore,
      callback: function ($$v) {
        _vm.minScore = $$v;
      },
      expression: "minScore"
    }
  }), _c('v-checkbox', {
    attrs: {
      "label": "Send Email?"
    },
    model: {
      value: _vm.sendEmail,
      callback: function ($$v) {
        _vm.sendEmail = $$v;
      },
      expression: "sendEmail"
    }
  }), _vm.setMinScore ? _c('v-alert', {
    attrs: {
      "type": "warning",
      "dense": "",
      "outlined": ""
    }
  }, [_vm._v(" " + _vm._s(_vm.autoRejectMessage) + " ")]) : _vm._e()], 1)], 1) : _vm._e()], 1), _c('div', [_c('v-row', [_c('v-col', [_vm._v(" Test Deadline (from invite time) ")])], 1), _c('v-row', _vm._l(_vm.timeUnits, function (unit) {
    return _c('v-col', {
      key: unit.label,
      attrs: {
        "cols": "3"
      }
    }, [_c('v-text-field', {
      attrs: {
        "label": unit.label,
        "type": "number",
        "placeholder": `Enter ${unit.label}`
      },
      model: {
        value: unit.value,
        callback: function ($$v) {
          _vm.$set(unit, "value", $$v);
        },
        expression: "unit.value"
      }
    })], 1);
  }), 1)], 1)], 1)], 1)], 1)], 1), _c('v-row', [_c('v-col', [_c('v-btn', {
    staticClass: "text-none text-body-1",
    attrs: {
      "color": "primary",
      "text": "",
      "id": "add-test-url"
    },
    on: {
      "click": _vm.openStartTime
    }
  }, [_c('v-icon', {
    attrs: {
      "left": "",
      "color": "primary"
    }
  }, [_vm._v(" " + _vm._s(_vm.mdiLink) + " ")]), _vm._v(" Add Test Start Time ")], 1), _c('time-select', {
    ref: "startTime",
    model: {
      value: _vm.startEpoch,
      callback: function ($$v) {
        _vm.startEpoch = $$v;
      },
      expression: "startEpoch"
    }
  }), _c('v-btn', {
    staticClass: "text-none text-body-1",
    attrs: {
      "color": "primary",
      "text": "",
      "id": "add-test-url"
    },
    on: {
      "click": _vm.openEndTime
    }
  }, [_c('v-icon', {
    attrs: {
      "left": "",
      "color": "primary"
    }
  }, [_vm._v(" " + _vm._s(_vm.mdiLink) + " ")]), _vm._v(" Add Test End Time ")], 1), _c('time-select', {
    ref: "endTime",
    model: {
      value: _vm.endEpoch,
      callback: function ($$v) {
        _vm.endEpoch = $$v;
      },
      expression: "endEpoch"
    }
  })], 1)], 1), _c('v-row', [_c('v-col', [_vm.startEpoch ? _c('v-alert', {
    attrs: {
      "type": "success",
      "dense": "",
      "outlined": ""
    }
  }, [_vm._v(" Test Start Time: " + _vm._s(_vm.formatDate(_vm.startEpoch)) + " ")]) : _vm._e()], 1), _c('v-col', [_vm.endEpoch ? _c('v-alert', {
    attrs: {
      "type": "success",
      "dense": "",
      "outlined": ""
    }
  }, [_vm._v(" Test End Time: " + _vm._s(_vm.formatDate(_vm.endEpoch)) + " ")]) : _vm._e()], 1)], 1)], 1), _c('v-stepper-content', {
    attrs: {
      "step": "4"
    }
  }, [_c('v-card', {
    attrs: {
      "elevation": "0"
    }
  }, [_c('v-card-title', [_c('v-row', {
    staticClass: "pa-0 ma-0"
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('b', [_vm._v("Test Name:")]), _vm._v(" " + _vm._s(_vm.testname) + " ")]), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('b', [_vm._v("Role:")]), _vm._v(" " + _vm._s((_vm$role = _vm.role) !== null && _vm$role !== void 0 ? _vm$role : 'Not set') + " ")])], 1)], 1), _c('v-card-text', {
    staticClass: "text-body-1 px-8 black--text"
  }, [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('div', {
    staticClass: "text-h6 pt-5 font-weight-bold"
  }, [_vm._v("Test Skills")]), _c('ol', _vm._l(_vm.descTasks, function (sk) {
    return _c('li', {
      key: sk.id
    }, [_vm._v(" " + _vm._s(sk.title) + " ")]);
  }), 0), _c('div', {
    staticClass: "text-h6 pt-5"
  }, [_c('b', [_vm._v("Total Test Duration: ")]), _vm._v(" " + _vm._s(_vm.displayTime(_vm.totalTimeInS)) + " ")]), _c('div', {
    staticClass: "text-h6 pt-5"
  }, [_c('b', [_vm._v("Anti-cheat settings: ")])]), _c('ul', {
    staticClass: "text-body-1"
  }, [_vm.extCopy ? _c('li', [_vm._v("Disallow External Copy Paste")]) : _vm._e(), _vm.tabLog ? _c('li', [_vm._v("Log Tab Change Events")]) : _vm._e(), _vm.makeFullscreen ? _c('li', [_vm._v("Make Fullscreen")]) : _vm._e(), _vm.videoRecording ? _c('li', [_vm._v("Take Video Recordings")]) : _vm._e()])]), _c('v-col', {
    staticClass: "d-flex flex-column text-h6",
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('div', {
    staticClass: "d-flex flex-row"
  }, [_c('b', [_vm._v("Enable Test URL:")]), _c('div', {
    staticClass: "pl-3"
  }, [_vm._v(" " + _vm._s(_vm.testByUrlEnabled ? 'Yes' : 'No') + " ")])]), _c('div', {
    staticClass: "d-flex flex-row"
  }, [_vm.testByUrlEnabled ? _c('b', [_vm._v("Test URL Expiry:")]) : _vm._e(), _c('div', {
    staticClass: "pl-3"
  }, [_vm._v(" " + _vm._s(_vm.testByUrlEnabled ? new Date(_vm.testUrlExpiryEpoch).toDateString() : '') + " ")])])]), _c('v-col', {
    staticClass: "d-flex flex-column text-h6"
  }, [_c('div', {
    staticClass: "d-flex flex-row"
  }, [_c('b', [_vm._v("Test Deadline:")]), _c('div', {
    staticClass: "pl-3"
  }, [_vm.hasDeadline ? _c('span', _vm._l(_vm.timeUnits, function (unit) {
    return _c('span', {
      key: unit.label
    }, [_vm._v(" " + _vm._s(unit.value) + " " + _vm._s(unit.label) + " ")]);
  }), 0) : _c('span', [_vm._v(" None ")])])])])], 1)], 1)], 1)], 1)], 1)], 1), _c('v-spacer'), _c('v-card-actions', {
    staticClass: "justify-space-between pb-16"
  }, [_c('v-btn', {
    staticClass: "px-5 py-2 text-none",
    attrs: {
      "color": "gray",
      "outlined": "",
      "disabled": _vm.pos === 1
    },
    on: {
      "click": _vm.back
    }
  }, [_vm._v(" Back ")]), _c('v-btn', {
    staticClass: "text-none text-subtitle-1 px-5 py-2",
    attrs: {
      "color": "success",
      "disabled": _vm.pos === 2 && !(_vm.skillIds.size > 0 && _vm.skillIds.size <= 5) || _vm.hiringPlan == 'Free' && _vm.antiCheat.includes(this.cheatingSettingsValues['ANTI_CHEATING_VIDEO_RECORDING']),
      "loading": _vm.submitted
    },
    on: {
      "click": _vm.next
    }
  }, [_vm._v(" " + _vm._s(_vm.pos === 4 ? 'Finish' : 'Next') + " ")])], 1), _c('div', [_c('go-premium', {
    ref: "unlock",
    attrs: {
      "imageUrl": "images/GoPremium.png"
    }
  })], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }